export const letterClasses = ['.yy-base',
   '.yy-top',
   '.ye-base',
   '.ye-top1',
   '.ye-top2',
   '.yl1',
   '.yl2',
   '.yo-base',
   '.yo-top',
   '.yw-base',
   '.yw-top',
   '.cc1-base',
   '.cc1-top',
   '.co-base',
   '.co-top',
   '.cl1',
   '.cl2',
   '.ce1-base',
   '.ce1-top1',
   '.ce1-top2',
   '.cc2-base',
   '.cc2-top',
   '.ct-base',
   '.ct-top',
   '.ci-base',
   '.ci-top',
   '.cv-base',
   '.cv-top',
   '.ce2-base',
   '.ce2-top1',
   '.ce2-top2']

export const letterNames = ['yy-base',
   'yy-top',
   'ye-base',
   'ye-top1',
   'ye-top2',
   'yl1',
   'yl2',
   'yo-base',
   'yo-top',
   'yw-base',
   'yw-top',
   'cc1-base',
   'cc1-top',
   'co-base',
   'co-top',
   'cl1',
   'cl2',
   'ce1-base',
   'ce1-top1',
   'ce1-top2',
   'cc2-base',
   'cc2-top',
   'ct-base',
   'ct-top',
   'ci-base',
   'ci-top',
   'cv-base',
   'cv-top',
   'ce2-base',
   'ce2-top1',
   'ce2-top2']