import './App.css';

import Homepage from './pages/Home';
import React from 'react';

function App() {
   return (
      <div className="App">
         <Homepage />
      </div>
   );
}

export default App;
